<template>
  <section>
    <!--<va-breadcrumbs>
        <va-breadcrumbs-item label="Calendario Letivo" to="/ambiente-coordenador/calendario-coordenador" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Suas Turmas  </span
          >
        </va-breadcrumbs-item>
      </va-breadcrumbs>-->
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->

    <h2 class="titulo-links-escolha" style="color: #000000;" v-if="user">Gerenciar Turmas</h2>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-12">
            <pm-DataTable :value="item"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
              responsiveLayout="scroll">
              <template #header>

              </template>
              <pm-Column field="escola" sortable header="ESCOLA" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
              <pm-Column field="segmento" sortable header="SEGMENTO" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
              <pm-Column field="serie" sortable header="PERÍODO" style="font-size: 14px;vertical-align:middle !important">
                <template #body="{data}">
                  <div>
                    {{data.serie}}
                  </div>
                </template>
              </pm-Column>
              <pm-Column field="nome" sortable header="TURMA" style="text-align:center;font-size: 14px;vertical-align:middle !important"></pm-Column>

              <pm-Column field="id" headerStyle="width: 4rem; text-align: center"
                bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
                <template #body="{data}">
                  <div v-if="data.ativa">
                    <pm-Button v-if="data.creche == 0" icon="pi pi-check-circle" title="Presença"
                      class="p-button-raised p-button-rounded  mr-2 btn-semsombra" @click="presenca(data)"
                    />
                  </div>
                </template>
              </pm-Column>
            </pm-DataTable>
          </div>
        </div>
      </template>
    </pm-Card>

    <div class="row" v-html="item_escola"> </div>
    <va-button class="mr-2 mb-2" color="danger" @click=" this.$router.push({name: 'coordenacao-turma-hab', params: { pg: this.pg } });">Voltar</va-button>
  </section>
</template>

<script>

import { defineComponent } from 'vue'
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";

import { Coordenador } from "@/class/coordenador.js";

export default defineComponent({
  props:{
    pg:{}
  },
  components: {

  },
  data () {
    const columns = [
      {key: 'id_', label:'Visualizar' },
      {key: 'nome', label:'Escola', sortable: true },
      {key: 'logradouro'},
      {key: 'bairro', sortable: true},
      { key: 'id', label:'Ação'},
    ]
    return {
      home: {icon: 'pi pi-home', to: '/ambiente-coordenacao/coordenacao-escolas'},
      items_bread: [
        {label: 'Suas Turmas'},
      ],
      item: [],
      user: "",
      coordenador:[],
      calendarioAll: [],
      ano:0,
      escolas:[],

      item_escola:"",
    }
  },


  methods:{
    async listarTudoCalendario() {
        try {
           const data = await Calendario.calendarioAtual();
           this.calendarioAll = data.data;
           this.ano = data.data.ano;
        } catch (e) {
           console.log(e);
        }
    },
    async listarTurma() {
        try {
          const id = this.coordenador.id;
          const coordenacao =  sessionStorage.getItem("coordenacao");
          let data = [];
          if(coordenacao == 2){
            data = await Coordenador.obtemUmServidor(id);
            this.escolas = data.data.esc;

            for (let index = 0; index < this.escolas.length; index++) {
              const payload ={
                ano: this.ano,
                escola_id: this.escolas[index],
                servidor_id: data.data.servidor_id,
              }
              const dt1 = await Coordenador.obtemTodosTurma(payload);
              const es = dt1.data.data;
              let item = "";
              const escola_nome = "<div class='flex xs12'> <h5>"+dt1.data.escola+"</h5> </div>";
              for (let i = 0; i < es.length; i++) {
                item += "<a class='flex xs3' href='/ambiente-coordenacao/turma/"+es[i].id+" '> <div class='va-card'> <div class='va-card__inner' > <div class='flex xs12' > <h6 style='text-align:center;color: #316de5;'>"+es[i].segmento+" </h6> <h6 style='text-align:center;color: #316de5;'>"+es[i].serie+" </h6> <h5 style='text-align:center'><b>"+es[i].nome+"</b></h5> </div>   </div></div></a>";
              }

              this.item_escola += escola_nome;
              this.item_escola +=item;
            }
          }
        } catch (e) {
           console.log(e);
        }
    },
    async listarTudo() {
        try {
          const token = sessionStorage.getItem("token");
          const whoiam = await LoginInfo.WhoIam(token);
          const calendario = await Calendario.calendarioAtual();
          if(whoiam.data.funcao != 4 ){
            this.$router.push({ name: "login"});
          }

          this.user = whoiam.data.usuario.nome;
          this.coordenador = whoiam.data.usuario;

          await this.listarTurma();

        } catch (e) {
           console.log(e);
        }
    },

  },
  async beforeMount() {
//    await this.listarTudoCalendario();
    //await this.listarTudo();
  }

})


</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }
</style>



<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
  $font: 'Josefin Sans', sans-serif;
  $accent: #ffcc80;

  .title{
    color: $accent;
  }
  .af{
    display: block;
    font-size: 1.1em;
    position: fixed;
    bottom: 1em;
    right: 1em;
    &__auth{
      display: block;
      margin-top: 0.5em;
      font-size: 0.9em;
    }
  }
  .circle-navigation{
    position: absolute;
    /*top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
    z-index: 1;
    &__nav-btn{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: none;
      transition: .3s ease;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      font-family: $font;
      background-color: #ffe0b2;
      z-index: 15;
      outline: none;
      box-shadow: 1px 2px 10px #ffb74d;
      &:hover{
        background: $accent;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:active{
        background: #ff9800;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 1px 2px 1px #ffb74d;
      }
    }
    &__list{
      list-style-type: none;
      padding: 0;
      margin: 0;
      //position: absolute;
      top: 4px;
      left: 6px;
      z-index: -1;
    }
    &__item{
      z-index: -1;
      position: absolute;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      transition-duration: .2s;
      border-radius: 50%;
      background-color: $accent;
      top: 7px;
    }
    &__link{
      color: #000;
      text-decoration: none;
      display:block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;

      text-align: center;
      padding-top: 22px;
      &:hover{
        background-repeat: no-repeat!important;
        background-position: center!important;
        transition: .3s ease;
        background: $accent;
      }
    }
  }
  .icon-position{
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
